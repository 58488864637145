/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"
import { Box, useColorMode } from "@chakra-ui/react";

const Layout = ({ children, pb }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <StyleColorMode pb={pb}>
      {children}
    </StyleColorMode>

  )
}

function StyleColorMode({ children, pb }) {
  const { colorMode } = useColorMode();
  const bgColor = { light: "white", dark: "#183560" };
  const color = { light: "secondaryIndie", dark: "white" };

  // console.log(colorMode)

  return (
    <Box bg={bgColor[colorMode]} color={color[colorMode]} minH="100vh" paddingBottom={typeof pb !== 'undefined' ? pb : '100px'}>
      {children}
    </Box>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
